@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

:root{
    --primary: rgb(246, 255, 0);
    --primary-light: rgb(252, 255, 160);
}

.text-primary{
    color: var(--primary) !important;
}
.text-primary-light{
    color: var(--primary-light) !important;
}
body{
    background-color: #0c0c0c;
    font-family: "Lora", serif;
}

.container{
    max-width: 95%;
}

p{
    color: white;
    font-size: 22px;
    letter-spacing: 1px;
}
h1,h2,h3{
    font-family: "Lora", serif;
    color: white;
    font-weight: 400;
}


.max-500{
    max-width: 500px;
}

.section-border{
    /* border-bottom: 5px solid var(--primary); */
}


.section-skills{
    background: #141414;
}
.section-title{
    font-size: 50px;
    color: var(--primary);;
}
@media(min-width:991px){
    .max-80{
        max-width: 80%;
        margin: 0 auto;
    }
    .mt-40{
        margin-top: 80px;
    }
}
@media(max-width:768px){
    .section-title{
        font-size: 40px;
    }
}

.mt-40{
    margin-top: 40px;
}
ul{
    padding: 0;
}
ul li{
    list-style: none;
    color: white;
    font-size: 22px;
}



.btn{
  padding: 10px 32px;
  border-radius: 24px;
  font-size: 1.125rem;
  border-width: 2px;
}
.btn-primary{
  background-color: transparent;
  border-color: white;
  color: white;
}
.btn-primary:hover,.btn-primary:active,.btn-primary:focus{
  background-color: white !important;
  border-color: white !important;
  color: black !important;
}

@media(max-width:568px){
  html{
      font-size: 85%;
  }
}




header .container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

header .nav-links{
  display: flex;
  align-items: center;
  gap: 40px;
}

header .name{
  color: white;
  font-size: 1.375rem;
  text-decoration: none;
}

header .nav-link{
  text-decoration: none;
  color: white;
  font-size: 1.25rem;
}
header .nav-link:hover{
  color: var(--primary-light);
}
.navbar-toggler{
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}


@media(max-width:991px){
  .navbar-collapse{
      position: absolute;
      background: rgb(252, 255, 160);
      width: 80%;
      left: 0px;
      padding: 0px 20px;
      z-index: 2;
      top: 0px;
      padding-top: 80px;
  }
  header .nav-link{
      color: black;
  }
}
.hamburgerIcon{
  display: inline-block;
  width: 20px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}
.hamburgerIcon span{
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--primary-light);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.hamburgerIcon span:nth-child(1) {
  top: 0px;
}

.hamburgerIcon span:nth-child(2),.hamburgerIcon span:nth-child(3) {
  top: 6px;
}

.hamburgerIcon span:nth-child(4) {
  top: 12px;
}

.navbar-toggler:not(.collapsed) .hamburgerIcon span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.navbar-toggler:not(.collapsed) .hamburgerIcon span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navbar-toggler:not(.collapsed) .hamburgerIcon span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navbar-toggler:not(.collapsed) .hamburgerIcon span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.section{
  padding: 60px 0;
}

.hero-section p{
  font-size: 1.375rem;
}
.hero-section{
  min-height: calc(100vh - 86px);
  display: flex;
  align-items: center;
  width: 100%;
  /* border-bottom: 5px solid var(--primary); */
  position: relative;
}
.hero-bg-icon{
  position: absolute;
  font-size: 500px;
  z-index: -1;
  color: #141414;
}
@media(max-width:768px){
  .hero-bg-icon{
      font-size: 300px;
      left: 50%;
      transform: translateX(-50%);
  }
}
.hero-section div:not(.container){
  width: 100%;
}
.hero-section h1{
  font-size: 5rem;
}

.skills-section{
  padding: 0;
  margin: 0;
  margin-top: 40px;
}
.skills-section ul li:not(:last-child){
  margin-bottom: 30px;
}
.skill-items{
  margin-top: 20px;
}
.skill-items .skill{
  display: inline-block;
  background-color: var(--primary-light);
  padding: 6px 20px;
  border-radius: 24px;
  color: black;
  font-size: 1.125rem;
  margin-right: 10px;
}

@media(max-width:768px){
  .skill-items .skill{
      margin-bottom: 10px;
  }
}

.experiences .companyName{
  position: relative;
  padding-left: 50px;
}
.experiences .position{
  margin-top: 25px;
  padding-left: 50px;
}
.experiences .position .position-name{
  position: relative;
  padding-left: 20px;
  font-weight: 700;
  color: var(--primary);
}
.experiences .position .position-year{
  color: rgb(212, 212, 212);
  padding-left: 20px;
  font-size: 1.1875rem;
  margin-bottom: 20px;
}
.experiences .position .position-desc{
  padding-left: 20px;
}
.experiences .position .position-desc p{
  font-size: 1.25rem;
}
.experiences .position .position-name::before{
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-light);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.experiences .position .position-name::after{
  content: "";
  position: absolute;
  width: 1px;
  height: 250px;
  background: linear-gradient(var(--primary-light),#0c0c0c);
  left: 5px;
  top: 15px;
}
.experiences .companyName::before{
  content: "\f1ad";
  font-family: "Font Awesome 6 Free";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--primary);
  color: black;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.socialLinks{
  display: flex;
  justify-content: center;
  gap: 25px;
}
.socialLinks li{
  width: 40px;
  height: 40px;
  background-color: rgb(219, 219, 219);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialLinks li a i{
  color: black;
}


footer{
  background-color: #141414;
  padding: 20px 0;
}
.footerInner{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerInner p{
  margin: 0;
  font-size: 1rem;
  color: rgb(239, 239, 239);
}
.scrollTop{
  width: 30px;
  height: 30px;
  background-color: var(--primary-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.scrollTop:hover,.scrollTop:focus{
  background-color: var(--primary);
}
.scrollTop i{
  color: black;
}

.fixedIcons{
  position: fixed;
  inset: auto 80px 80px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media(max-width:576px){
  .fixedIcons{
      inset: auto 20px 80px auto;
  }
}
.fixedIcons .item a{
  display: inline-block;
  text-decoration: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: rgb(219, 219, 219);
}
.fixedIcons .item a i{
  color: black;
  font-size: 18px;
}

.fixedIcons .item a:hover{
  background-color: rgb(207, 207, 207);
}